<template>
  <div class="container container-container">
    <ul class="nav nav-tabs mt-3">
      <li class="nav-item" v-for="nav in navItems" :key="nav.name">
        <router-link
            :to="{name:nav.name}"
            class="nav-link"
            :class="{active:$route.name === nav.name, disabled:!isStore}"
        >{{ nav.label }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const isStore = computed(() => !!store.state.eshop.store)

const navItems = [
  {name: 'Dashboard', label: 'Dashboard'},
  {name: 'Accounting', label: 'Transactions'},
  {name: 'Inventory', label: 'Inventory'},
  // {name: 'Expenses', label: 'Expenses'},
  {name: 'Ebay', label: 'eBay'},
  {name: 'Settings', label: 'Settings'},
]
</script>

<style scoped>
.nav-link.disabled {color: #ccc !important; cursor: not-allowed; font-style: italic;}
</style>
