<template>
  <div class="justify-content-center esc-spinner" :class="{'d-inline-flex': props.small, 'd-flex': !props.small}">
    <div class="spinner text-info" :class="{'spinner-sm':props.small}" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from "vue"
const props = defineProps(['small'])
</script>

<style scoped>

.spinner {
  position: relative;
  width: 48px;
  height: 48px;
  display: inline-block;
  margin: 0;
  animation: throb 3s infinite linear;
}
.spinner::after {
  background: url('/android-chrome-192x192.png') no-repeat center center / contain;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation: throb 6s infinite linear;
}

.spinner-sm {
  width: 1rem;
  height: 1rem;
}


@keyframes throb {
  0% {opacity: 0.8; transform: scale(0.7) rotate(0deg);}
  50% {opacity: 1; transform: scale(1.2) rotate(180deg);}
  100% {opacity: 0.8; transform: scale(0.7) rotate(360deg);}
}
</style>
