<template>
  <div>
    <div class="list-group">
      <div v-if="eshop"
          type="button"
          class="list-group-item list-group-item-action"
          @click="selectStore"
          :key="eshop.id"
      >{{ eshop.name }}</div>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick} from 'vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {useAuth0} from '@auth0/auth0-vue'

const store = useStore()
const router = useRouter()
const auth0 = useAuth0()
const eshop = computed(() => store.state.eshop.store)
selectStore()

async function selectStore() {
  await nextTick()
  if (!eshop.value) return
  store.dispatch('eshop/selectStore', {auth0, store: eshop.value})
      .then(() => router.push({name: 'Dashboard', params: {storeKey: eshop.value.id}}))
      .catch(e => {
        alert(e.message)
      })

}

</script>

<style lang="scss" scoped>

</style>
