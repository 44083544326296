
import AxiosService from '@/services/AxiosService';
const api = new AxiosService('/api');

export default  {
  namespaced: true,
  state: {
    configKeys: []
  },
  getters: {
    configKeys: state => state.configKeys.slice().sort((a,b) => a.sequence - b.sequence)
  },
  actions: {
    async getConfigKeys({ commit }, {auth0}) {
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('/admin/get-config-keys')
        .then(response => response.data)
        .then(data => data.keys)
        .then(keys => {
          commit('SET_CONFIG_KEYS', keys || [])
          return keys || []
        })
    }
  },
  mutations: {
    SET_CONFIG_KEYS(state, configKeys) {
      state.configKeys = configKeys || []
    }
  }
}
