<template>
  <div v-if="isManager">
    <store-nav />
    <div id="main-content" class="container container-container">
      <div id="main" class="bg-white border-start border-end p-4 pb-5">
        <router-view v-if="store.state.eshop.store" />
      </div>
    </div>
  </div>
</template>

<script setup>
import StoreNav from "@/components/StoreNav.vue";
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router";

const store = useStore()
const router = useRouter()

const isManager = computed(() => store.getters.hasRole('manager.eshop'))
const eshop = computed(() => store.state.eshop.store)

if (!eshop.value) {
  router.replace({name: 'Home'})
}

</script>
