import { createStore } from 'vuex'
import admin from './adminStore'
import eshop from './eshopStore'

export default createStore({
  modules: {
    admin,
    eshop,
  },
  state: {
    initialized: false,
    appName: 'eShopCommand',
    tagLine: 'Take Command of Your eCommerce',
    modalIn: false,
    roles: [],
  },
  getters: {
    modalIn: state => state.modalIn,
    hasRole: state => role => {
      return state.roles.indexOf(role) !== -1
    }
  },
  mutations: {
    initialize (state) {
      state.initialized = true
    },
    closeModal (state) {
      state.modalIn = false
    },
    setToken(state, token) {
      state.token = token
      if (token) {
        sessionStorage.setItem('ec-token', token)
      } else {
        sessionStorage.removeItem('ec-token')
      }
    },
    setUser(state, user) {
      state.user = user
    },
    setRoles(state, roles) {
      state.roles = roles || []
    },
    toggleModal(state, inStatus) {
      state.modalIn = !!inStatus
    }
  },
  actions: {
    logOut({ commit }) {
      commit('setToken', null)
      commit('setStores', [])
      commit('setStore', null)
      commit('setUser', null)
      return Promise.resolve()
    }
  }
})
