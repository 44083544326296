
import AxiosService from '@/services/AxiosService'
const api = new AxiosService(process.env.NODE_ENV === 'production' ? '/api' : '/api');
import ebay from "@/services/ebay";

export default {
  namespaced: true,
  state: {
    store: null,
    globalConfigs: [
      'WAVE_BUSINESS_ID',
      'WAVE_ANCHOR_ACCOUNT_ID',
      'WAVE_SHIPPING_ACCOUNT_ID',
      'WAVE_SALES_ACCOUNT_ID',
      'WAVE_EBAY_FEES_ACCOUNT_ID',
      'WAVE_AD_FEES_ACCOUNT_ID',
      'ANCHOR_ACCOUNT_ID',
      'SHIPPING_ACCOUNT_ID',
      'SALES_ACCOUNT_ID',
      'EBAY_FEES_ACCOUNT_ID',
      'AD_FEES_ACCOUNT_ID',
    ],
    ebayRefreshToken: window.localStorage.getItem('esc-ebay-refresh') || null,
    ebayUserToken: null,
  },
  getters: {
    config: state => key => state.store.config[key] || null,
  },
  actions: {
    async loadStore({ commit }, auth0) {
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('/store')
        .then(response => response.data)
        .then(st => {
          commit('setStore', st)
          return st
        })
    },
    async selectStore({ commit }, {auth0, store}) {
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('/store/get-config', {
        params: {
          storeKey: store.id
        }
      })
        .then(response => response.data)
        .then(data => data.config)
        .then(conf => {
          commit('setStore', {...store, config: conf})
        })
    },
    async createStore({ commit }, {auth0, name}) {
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).post('/stores/store', {
        name: name
      }).then(response => response.data)
        .then(data => data.store)
        .then(store => commit('addStore', store))

    },
    async saveStoreConfig({ state, commit }, {auth0, key, val}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).post('/store/config', {
        storeKey: state.store.id,
        key: key,
        val: val,
      })
        .then(response => response.data)
        .then(data => data.conf)
        .then(conf => {
          const configuration = state.store.config
          configuration[conf.data_key] = conf.data_value
          commit('setStore', {...state.store, config: configuration})
        })
      // .then(store => {
      //   commit('setStore', store)
      //   commit('eshop/updateStores', store)
      // })
    },
    async getTransactions({ state }, {auth0, date}){
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const ebayService = new ebay(state.store.config)
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('transactions/get', {
        params: {storeId: state.store.id, date}
      })
        .then(response => response.data)
        .then(ebayService.transactionsToInputs)
    },
    async checkTransaction( { state }, {auth0, key} ) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('transactions/check', {
        params: {
          transactionId: key,
          storeId: state.store.id
        }
      })
        .then(response => response.data)
        .then(data => data.imported)

    },

    async getSales({ state }, {auth0, fromDate, interval}) {
      if (!auth0.isAuthenticated) {
        return Promise.reject('not authenticated')
      }
      if (!fromDate) {
        return Promise.reject(`invalid fromDate ${fromDate}`)
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('transactions/sales', {
        params: {
          f: fromDate,
          interval,
          storeId: state.store.id
        }
      })
        .then(response => response.data)
    },

    async getEbayData({ state }, {auth0, type, dates}){
      if (!auth0.isAuthenticated || !state.store.config.EBAY_USER_ACCESS_TOKEN) {
        return Promise.resolve('not authenticated')
      }

      const ebayService = new ebay(state.store.config)

      const token = await auth0.getAccessTokenSilently()
      const url = type === 'payouts' ? 'ebay/get-payout-data' : 'ebay/get-data'
      return api.setToken(token).post(url, {
        accessToken: state.store.config.EBAY_USER_ACCESS_TOKEN,
        type: type,
        dates: dates,
        store: state.store.id
      })
        .then(response => response.data)
        .then(data => data.transactions || data.payouts)
        .then(transactions => ebayService.transactionsToInputs(transactions))
        .then(inputs => {
          return inputs
        })
    },
    async mapTransaction({ state }, {auth0, transactionId}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('transactions/map', {
        params: {
          transactionId: transactionId,
          storeId: state.store.id
        }
      })
        .then(response => response.data)
        .then(data => data.imported)
    },
    async addAccountTransaction({ state }, {auth0, input}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).post('accounts/add-account-transaction', {
        input: input,
        storeId: state.store.id
      })
        .then(response => response.data)
    },
    addWaveInput({state}, input) {
      const ebayService = new ebay(state.store.config)
      return ebayService.addTransaction(input)
        .then(response => response.json())
    },
    getWaveBusinesses({state}) {
      const ebayService = new ebay(state.store.config)
      return ebayService.getBusinesses()
        .then(response => response.json())
        .then(body => {
          if (body.errors) {
            throw new Error (body.errors[0].message)
          }
          return body.data
        })
        .then(data => data.businesses)
        .then(businesses => businesses.edges || [])
        .then(b => b.map(bus => bus.node))
    },
    async getAccounts({state}, {auth0}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token)
        .get('/accounts', {
          params: {
            storeId: state.store.id
          }})
        .then(response => response.data)
    },
    getWaveAccounts({state, commit}, page=0) {
      if (state.store.accounts) return Promise.resolve(state.store.accounts)
      const ebayService = new ebay(state.store.config)
      return ebayService.getAccounts(page)
        .then(response => response.json())
        .then(body => {
          if (body.errors) {
            throw new Error (body.errors[0].message)
          }
          return body.data
        })
        .then(data => data.business)
        .then(business => business.accounts)
        .then(a => a.edges)
        .then(edges => edges.map(e => e.node))
        .then(accounts => {
          commit('setStore', {...state.store, accounts})
          return accounts
        })
    },
    async getEbayAuthUrl({ state }, auth0) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      //return Promise.resolve('https://auth.ebay.com/oauth2/authorize?client_id=DoliStep-eShopCom-PRD-d90b458a2-56eade98&response_type=code&redirect_uri=Doli_Stepniewsk-DoliStep-eShopC-vlurcqrnx&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/sell.reputation https://api.ebay.com/oauth/api_scope/sell.reputation.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly https://api.ebay.com/oauth/api_scope/sell.stores https://api.ebay.com/oauth/api_scope/sell.stores.readonly')
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token)
        .get('/ebay/get-auth-url', {
          params: {
            state: state.store.id
          }})
        .then(response => response.data)
        .then(data => data.authUrl)
    },
    async getEbayItem(s, {auth0, itemId}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token)
        .post('/ebay/get-item', {itemId})
        .then(response => response.data)
    },
    async getSellerItems(s, {auth0, granularity, limit, page}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token)
        .post('/ebay/get-seller-items', {granularity, limit, page})
        .then(response => response.data)
    },
    async getEbayOrder({ commit }, {auth0, orderId}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token)
        .post('/ebay/get-order', {orderId})
        .then(response => response.data)
        .catch(error => {
          if (error.status === 401) {
            commit('setEbayAccessToken', null)
            commit('setEbayRefreshToken', null)
          }
          throw error
        })
    },
    async fetchStoredEbayTokens( s, { auth0 }) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token).get('/ebay/get-stored-token')
        .then(response => response.data)
    },
    async getEbayUserToken(s, {auth0, code, type}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      type = type || 'code'
      const token = await auth0.getAccessTokenSilently()
      return api.setToken(token)
        .get('/ebay/callback', {params:{code, type}})
        .then(response => response.data)
        .then(data => data.token)
        .then(token => {
          if (token.access_token) {
            return Promise.resolve({validToken: true, token: token.access_token})
          }
          return Promise.resolve({validToken: false, token: false})
        })
    },
  },
  mutations: {
    setEbayRefreshToken(state, refreshToken) {
      state.ebayRefreshToken = refreshToken
      window.localStorage.setItem('esc-ebay-refresh', refreshToken)
    },
    setEbayAccessToken(state, accessToken) {
      state.ebayUserToken = accessToken
    },
    setStore: (state, payload) => {
      if (!payload) {
        state.store = null
        return
      }
      state.store = payload // {...payload, config: JSON.parse(payload.config || "{}")}
    }
  }
}
